import React, {Component} from 'react'
import { Layout, Menu } from 'antd';
import { withRouter} from 'react-router-dom'
import {getSysUserMenu} from '../../request'
import * as Icon from '@ant-design/icons';
import IconFun from '../../public/icon'

const { Sider } = Layout;

class Aside extends Component {
    state = {
        menus:[],
        selectKeys:[this.props.location.pathname.split(`/${this.props.sysLink}/`)[1]||'dashboard']
    }
    getItem = (label, key, icon, children, type)=> {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    componentDidMount(){
        let param = this.getItem('首页', 'dashboard', <IconFun icon={'AppstoreOutlined'}/>)
        console.log(22,param)
        getSysUserMenu({serviceId:'LECHUN-'+this.props.sys}).then(res=>{
            let menuList = res.length>0?res[0]['menu_list']:[]
            let setMenu = [...menuList].map(item=>{
                return this.getItem(item.resourceName, item.resourceHtmlUrl||item.resourceId, <IconFun icon={item.resourceIcon}/>, item.menu_list?(item.menu_list||[]).map(item1=>{
                    return this.getItem(item1.resourceName, item1.resourceHtmlUrl, <IconFun icon={item1.resourceIcon}/>)
                }):null)
            })
            this.setState({
                menus:[param,...setMenu]
            })
        })
    }


    routerFun = ({ item, key, keyPath, domEvent })=>{
        console.log('route',item,key,keyPath)
        this.setState({
            selectKeys:keyPath
        })
        if(key.indexOf('http://')>-1||key.indexOf('https://')>-1){
            window.open(key,'_blank')
        }else{
            this.props.history.push(`/${this.props.sysLink}/${key}`)

        }
    }
    render() {
        const selUrl = [this.props.location.pathname.split(`/${this.props.sysLink}/`)[1]||'dashboard']
        return (
            <Sider  style={{overflowY:'scroll'}} width="140" trigger={null} collapsible collapsed={this.props.collapsed} className="aside-layout">
                <Menu
                    forceSubMenuRender={true}
                    selectedKeys={[...selUrl]}
                    defaultOpenKeys={[...selUrl]}
                    mode="vertical"
                    items={this.state.menus} onClick={this.routerFun}/>
            </Sider>
        )
    }
}
export default withRouter(Aside)