import {apiGetRequest,apiPostRequest,apiPostJsonRequest,downloadUrlRequest,apiGetFullUrlRequest} from '../http/http'
/**
 * 登录request**/
export const loginRequest = (userInfo)=>{
    return apiGetRequest('/lechun-baseservice/auth/login',userInfo)
}
/**
 * 获取登录验证码
 * **/
export const getLoginImageValidateCode = (param)=>{
    return apiGetRequest('/lechun-baseservice/auth/captcha',param)
}
/**
 * 登出request**/
export const loginOutRequest = (userInfo)=>{
    return apiPostRequest('/lechun-baseservice/auth/logout',userInfo)
}
/*
* aside菜单
* */
export const getSysUserMenu = (param)=>{
    return apiGetRequest('/lechun-baseservice/auth/getSysUserMenu',param)
}
/*
* header用户信息
* */
export const getUserDetail = (param)=>{
    return apiGetRequest('/lechun-baseservice/auth/getUserDetail',param)
}
/*
* 修改密码
* */
export const updatePassWord = (param)=>{
    return apiPostRequest('/lechun-baseservice/user/updatePassWord',param)
}
/*
* 菜单管理
* */
export const getMallResourceList = (param)=>{
    return apiGetRequest('/lechun-baseservice/resource/getMallResourceList',param)
}
/*
* 保存菜单
* */
export const saveMallResource = (param)=>{
    return apiPostRequest('/lechun-baseservice/resource/saveOrUpdateMallResource',param)
}
/*
* 用户管理
* */
export const getMallUserList = (param)=>{
    return apiGetRequest('/lechun-baseservice/user/getMallUserList',param)
}
/*
* 用户管理-获取部门
* */
export const getUserDeparts = (param)=>{
    return apiGetRequest('/lechun-baseservice/user/getDeparts',param)
}
/*
* 用户管理-获取下拉用户列表
* */
export const getUserListForDropbox = (param)=>{
    return apiGetRequest('/lechun-baseservice/user/getUserListForDorpbox',param)
}
/*
* 用户管理-获取所属公司
* */
export const getUserPlatForm = (param)=>{
    return apiGetRequest('/lechun-baseservice/comm/platFormList',param)
}
/*
* 用户管理-保存用户管理
* */
export const saveOrUpdateMallUser = (param)=>{
    return apiPostRequest('/lechun-baseservice/user/saveOrUpdateMallUser',param)
}
/*
* 用户管理-保存用户管理
* */
export const handleUpdateDingDingUser = (param)=>{
    return apiPostRequest('/lechun-baseservice/dingDeptStaff/syncDeptStaff',param)
}
/*
* 用户管理-删除用户
* */
export const deleteUserRole = (param)=>{
    return apiPostRequest('/lechun-baseservice/user/deleteUserRole',param)
}
/*
* 用户角色管理
* */
export const getMallUserRoleList = (param)=>{
    return apiGetRequest('/lechun-baseservice/user/getMallUserRoleList',param)
}
/*
* 保存用户角色管
* */
export const saveUserRole = (param)=>{
    return apiPostRequest('/lechun-baseservice/user/saveUserRole',param)
}
/*
* 角色列表
* */
export const getMallRoleList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getMallRoleList',param)
}
/*
* 角色菜单权限列表
* */
export const getMallRoleResourceList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getMallRoleResourceList',param)
}
/*
* 保存角色
* */
export const saveOrUpdateMallRole = (param)=>{
    return apiPostRequest('/lechun-baseservice/role/saveOrUpdateMallRole',param)
}/*
* 保存角色权限选择
* */
export const saveRoleResource = (param)=>{
    return apiPostRequest('/lechun-baseservice/role/saveRoleResource',param)
}
/**
 * 周笔记获取接口
 * **/
export const getCooperateList = (param)=>{
    return apiGetRequest('/lechun-baseservice/organization/getPaperPageList',param)
}

/**
 * 分销渠道获取权限列表
 * **/
export const getSalesRoleList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getMallRoleDataList',param)
}
/**
 * 分销渠道获取权限类型
 * **/
export const getSalesTypeRoleList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getDataTypes',param)
}
/**
 * 分销渠道获取权限类型
 * **/
export const saveRoleSalesResource = (param)=>{
    return apiPostRequest('/lechun-baseservice/role/saveRoleData',param)
}

/**
 * 测试验证
 * **/
export const getControlSystemList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getControlSystems',param)
}
/**
 * 测试验证参数
 * **/
export const getControlSystemParam = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/setTestAuthor',param)
}
/**
 * 获取所有系统
 * **/
export const getBaseSystem = (param)=>{
    return apiGetRequest('/lechun-baseservice/resource/getBaseSystem',param)
}

/**
 * 测试验证保存
 * **/
export const saveControlSystem = (param)=>{
    return apiPostRequest('/lechun-baseservice/system/saveSystem',param)
}
/**
 * 观远bi单点登录
 * **/
export const gyBiSystemLogin = (param)=>{
    return apiGetRequest('/lechun-baseservice/sso/buildGuanyuanAccessTokenByLoginUser',param)
}
/**
 * 乐纯bi单点登录
 * **/
export const lcBiSystemLogin = (param)=>{
    return apiPostRequest('/lechun-baseservice/sso/buildMetaBaseAccessToken',param)
}
/**
 * 乐纯bi密码登录
 * **/
export const lcBiSystemLoginSubmit = (param)=>{
    return apiPostRequest('/lechun-baseservice/sso/saveMetaBasePassword',param)
}
/**
 * 观远bi iframe单点登录
 * **/
export const gyBiIframeLogin = (param)=>{
    return apiGetRequest('/lechun-baseservice/sso/buildGuanyuanAccessTokenNew',param)
}
/**
 * 获取消息列表
 * **/
export const getNotificationsList = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/portal/getUserMessageList',param)
}
/**
 * 获取消息数量
 * **/
export const getNotificationsCount = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/portal/getPortalCount',param)
}
/**
 * 获取消息选项列表
 * **/
export const getNotificationsOptions = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/portal/getMessageDictionary',param)
}

/**
 * 设置消息已读
 * **/
export const setNotificationsRead = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/readMessage',param)
}
/**
 * 处理消息
 * **/
export const handleNotifications = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/processMessage',param)
}
/**
 * 处理消息带链接
 * **/
export const handleLinkNotifications = (url)=>{
    return apiGetFullUrlRequest(url)
}
/**
 * 配置消息列表（后台）
 * **/
export const getConfigureMessageList = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/portal/getMessageList',param)
}
/**
 * 配置消息类型列表（后台）
 * **/
export const getConfigureClassMessageList = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/getMessageClassPageList',param)
}
/**
 * 配置消息类型详情（后台）
 * **/
export const getConfigureClassMessage = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/getMessageClass',param)
}
/**
 * 移动消息（后台）
 * **/
export const moveConfigureClassMessage = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/removeMessageClassPath',param)
}
/**
 * 复制消息（后台）
 * **/
export const copyConfigureClassMessage = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/copyMessageClass',param)
}
/**
 * 获取用户消息数据详情
 * **/
export const getNotificationsResultData = (param)=>{
    return apiGetRequest('/lechun-baseservice/portal/getUserMessageData',param)
}
/**
 * 获取消息数据详情
 * **/
export const getNotificationsMsgResultData = (param)=>{
    return apiGetRequest('/lechun-baseservice/portal/getMessageData',param)
}
/**
 * 配置消息类型详情（后台）
 * **/
export const getCronTimeData = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/getCronTime',param)
}
/**
 * 获取消息执行时间（后台）
 * **/
export const getInvokeMapKey = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/getInvokeMapKey',param)
}
/**
 * 保存消息类型(后台)
 * **/
export const saveNotificationData = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/portal/saveMessageClass',param)
}
/**
 * 删除消息类型(后台)
 * **/
export const delNotificationData = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/deleteMessageClass',param)
}
/**
 * 反馈字典列表
 * **/
export const getFeedbackDicList = (param)=>{
    return apiGetRequest('/lechun-baseservice/feedback/getDicList',param)
}
/**
 * 保存反馈
 * **/
export const saveFeedbackData = (param)=>{
    return apiPostRequest('/lechun-baseservice/feedback/saveFeedback',param)
}
/**
 * 处理反馈列表
 * **/
export const getSystemFeedbackList = (param)=>{
    return apiGetRequest('/lechun-baseservice/feedback/getSystemFeddbackList',param)
}
/**
 * 我的收藏
 * **/
export const getMyCollectData = (param)=>{
    return apiPostRequest('/lechun-baseservice/favorite/getFavoriteList',param)
}
/**
 * 保存收藏
 * **/
export const saveCollectData = (param)=>{
    return apiPostRequest('/lechun-baseservice/favorite/saveFavorite',param)
}

/**
 * 获取日历（后台）
 * **/
export const getCalendarDataList = (param)=>{
    return apiPostRequest('/lechun-baseservice/portal/findCalendars',param)
}
/**
 * 获取TB项目
 * **/
export const getProjects = (param)=>{
    return apiPostRequest('/lechun-bi/tb/getProjects',param)
}
/**
 * 获取TB字段
 * **/
export const getFields = (param)=>{
    return apiPostRequest('/lechun-bi/tb/getField',param)
}
/**OA**/
/**
 * 获取问卷项目列表
 * **/
export const getSurveyProjectList = (param)=>{
    return apiPostRequest('/lechun-baseservice/survey/getProjectList',param)
}/**
 * 获取问卷项目树形列表
 * **/
export const getSurveyProjectTreeList = (param)=>{
    return apiPostRequest('/lechun-baseservice/survey/getProjectOptionList',param)
}
/**
 * 获取问卷项目
 * **/
export const getSurveyProject = (param)=>{
    return apiGetRequest('/lechun-baseservice/survey/getProject',param)
}
/**
 * 保存问卷项目
 * **/
export const saveSurveyProject = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/survey/createProject',param)
}/**
 * 复制问卷项目
 * **/
export const copySurveyProject = (param)=>{
    return apiPostRequest('/lechun-baseservice/survey/copyProject',param)
}
/**
 * 删除问卷项目
 * **/
export const delSurveyProject = (param)=>{
    return apiPostRequest('/lechun-baseservice/survey/deleteProject',param)
}
/**
 * 获取问卷答题页
 * **/
export const getSurveyPage = (param)=>{
    return apiGetRequest('/lechun-baseservice/survey/loadProject',param)
}
/**
 * 提交问卷答题页
 * **/
export const submitSurveyPage = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/survey/saveAnswer',param)
}
/**
 * 获取360分类
 * **/
export const get360ClassList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getClassList',param)
}
/**
 * 保存360分类
 * **/
export const save360ClassList = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/eval/saveClass',param)
}
/**
 * 获取360字典
 * **/
export const get360DicList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEvalDicList',param)
}
/**
 * 获取360用户人员
 * **/
export const get360ClassUserList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getClassUserList',param)
}
/**
 * 保存360用户人员
 * **/
export const save360ClassUser = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/eval/addClassUser',param)
}
/**
 * 删除360用户人员
 * **/
export const del360ClassUser = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/deleteClass',param)
}
/**
 * 获取360问卷问题
 * **/
export const get360SurveyQaList = (param)=>{
    return apiGetRequest('/lechun-baseservice/survey/getSurveyQuestionOption',param)
}
/**
 * 获取360关联问卷列表
 * **/
export const get360ConnectSurveyList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getProjectRefList',param)
}
/**
 * 获取360问卷配置
 * **/
export const get360SurveySetting = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEval',param)
}
/**
 * 保存360问卷配置
 * **/
export const save360SurveySetting = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/saveEval',param)
}
/**
 * 保存360关联问卷列表
 * **/
export const save360ConnectSurveyList = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/eval/saveProjectRef',param)
}
/**
 * 获取下级KPI列表
 * **/
export const getSubordinateKpiList = (param)=>{
    return apiGetRequest('/lechun-baseservice/kpi/subKpis',param)
}
/**
 * 获取KPI详情
 * **/
export const getSubordinateKpiDetail = (param)=>{
    return apiGetRequest('/lechun-baseservice/kpi/myKpi',param)
}
/**
 * 保存kpi
 * **/
export const saveSubordinateKpi = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/kpi/saveKpi',param)
}
/**
 * 发布kpi
 * **/
export const publishSubordinateKpi = (param)=>{
    return apiPostRequest('/lechun-baseservice/kpi/publishKpi',param)
}
/**
 * 确认
 * **/
export const ensureSubordinateKpi = (param)=>{
    return apiGetRequest('/lechun-baseservice/kpi/ensureMyKpi',param)
}
/**
 * 退回KPI
 * **/
export const returnBackKpiSubordinateKpi = (param)=>{
    return apiGetRequest('/lechun-baseservice/kpi/turnBack',param)
}
/*
* 修改KPI填报方式
* */
export const changeKpiMakingType = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/kpi/changeKpiMakingType',param)
}
/**
 * 绩效评估列表
 * **/
export const getPerformanceEvaList = (param)=>{
    return apiGetRequest('/lechun-baseservice/values/myValues',param)
}
/**
 * 保存绩效
 * **/
export const savePerformanceEva = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/values/saveValues',param)
}
/**
 * 清空Leader评分
 * **/
export const clearLeaderPerformanceRate = (param)=>{
    return apiPostRequest('/lechun-baseservice/values/clearLeaderRate',param)
}
/**
 * 清空自评评分
 * **/
export const clearSelfPerformanceRate = (param)=>{
    return apiPostRequest('/lechun-baseservice/values/clearSubRate',param)
}
/**
 * 删除部门leader
 * **/
export const clearPerformanceSuperLeader = (param)=>{
    return apiPostRequest('/lechun-baseservice/values/clearSuperLeader',param)
}
/**
 * 添加部门负责人
 * **/
export const appointSuperLeader = (param)=>{
    return apiPostRequest('/lechun-baseservice/values/appointSuperLeader',param)
}
/**
 * 360得分汇总列表
 * **/
export const getScoreResult360List = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEvalTotalList',param)
}
/**
 * 计算360得分
 * **/
export const computeScore360 = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/computeEvalResult',param)
}
/**
 * 确认360得分
 * **/
export const setConfirmScore360 = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/setComplete',param)
}
/**
 * 批量计算360得分
 * **/
export const computeAllScore360 = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/manualComputeEvalResult',param)
}
/**
 * 批量设置360结束时间
 * **/
export const setAll360EndTime = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/batchReChangeEndTime',param)
}
/**
 * 手工发放问卷
 * **/
export const send360survey = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/autoBuilderEvalProjectDistribute',param)
}
/**
 * 重置结束时间父列表
 * **/
export const resetUserSurveyEndTime = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/reChangeEndTimeByUser',param)
}
/**
 * 重置结束时间子列表
 * **/
export const resetSurveyEndTime = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/reChangeEndTime',param)
}
/**
 * 评分列表
 * **/
export const getScoreList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/projectDistributeList',param)
}
/**
 * 评分列表
 * **/
export const getEvalScoreList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEvalPersonTotalList',param)
}
/**
 * 下级评分列表
 * **/
export const getSubordinateScoreList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEvalPersonSubList',param)
}
/**
 * 员工消息配置列表
 * **/
export const getOAMessageSysList = (param)=>{
    return apiGetRequest('/lechun-baseservice/sendConfig/getConfigList',param)
}
/**
 * 保存员工消息配置
 * **/
export const saveOAMessageSys = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/sendConfig/saveConfig',param)
}
/**
 * 员工管理列表
 * **/
export const getUserRefUsersList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getUserRefUsersList',param)
}
/**
 * 员工相关人列表
 * **/
export const getUserRefUsers = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getUserRefUsers',param)
}
/**
 * 员工管理保存peers
 * **/
export const saveUserRefPeers = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/eval/addUserPeers',param)
}
/**
 * 员工管理保存peers
 * **/
export const saveInsertSurveyQuestion = (param)=>{
    return apiPostRequest('/lechun-baseservice/survey/insertModuleQuestion',param)
}
/**
 * 定期设置peers获取期次
 * **/
export const getUserPeersByPeriod = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getUserPeersByPeriod',param)
}
/**
 * 定期设置peers获取期次
 * **/
export const copyLastPeriodPeers = (param)=>{
    return apiPostRequest('/lechun-baseservice/eval/copyLastPeriodPeers',param)
}
/**
 * sso配置列表（后台）
 * **/
export const getSsoDataList = (param)=>{
    return apiPostRequest('/lechun-baseservice/sso/getSSOList',param)
}
/**
 * sso配置保存（后台）
 * **/
export const saveSsoDataList = (param)=>{
    return apiPostRequest('/lechun-baseservice/sso/saveSSO',param)
}
/**
 * sso配置保存（后台）
 * **/
export const delSsoDataList = (param)=>{
    return apiPostRequest('/lechun-baseservice/sso/deleteSSO',param)
}
/**
 * 汇总报表
 * **/
export const getScoreResult = (param)=>{
    return apiGetRequest('/lechun-baseservice/scoreSum/sumList',param)
}
/**
 * 下载汇总报表
 * **/
export const downloadScoreResult = (param)=>{
    return downloadUrlRequest('/lechun-baseservice/scoreSum/sumExport',param)
}

/**
 * oa期次列表
 * **/
export const getEvalPeriodList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getEvalPeriodList',param)
}
/**
 * oa期次列表
 * **/
export const getEvalPeriodLogList = (param)=>{
    return apiGetRequest('/lechun-baseservice/eval/getDistributeLogPageList',param)
}
/*
* 修改KettlePassWord密码
* */
export const updateKettlePassWord = (param)=>{
    return apiPostRequest('/lechun-bi/kettle/updateKettlePassword',param)
}
/*
* 获取角色用户列表
* */
export const getRoleUserList = (param)=>{
    return apiGetRequest('/lechun-baseservice/role/getRoleUserList',param)
}
/*
* 保存角色用户
* */
export const saveRoleUser = (param)=>{
    return apiPostRequest('/lechun-baseservice/role/saveUserRole',param)
}
/*
* 获取scrm审核列表
* */
export const getMaterialCheckList = (param)=>{
    return apiGetRequest('/lechun-cms/scrmMaterial/getWaittingCheckMaterials',param)
}
/*
* 获取公告配置列表
* */
export const getAnnouncementList = (param)=>{
    return apiGetRequest('/lechun-baseservice/announcements/getAnnouncementList',param)
}
/*
* 保存公告配置
* */
export const saveAnnouncement = (param)=>{
    return apiPostJsonRequest('/lechun-baseservice/announcements/saveAnnouncement',param)
}

