import React, {Component} from 'react'
import {Spin
} from 'antd';
import {setNotificationsRead} from '../../request'

export default class NotificationsEmpty extends Component {
    state = {
        dataSource: [],
        formParam: {
            messageUserId: this.props.match.params.id,
        },
        messageInfo: {},
    }
    setNotificationsReadData = (id,returnUrl)=>{
        setNotificationsRead({messageUserId:id}).then((res)=>{
            window.location.replace(returnUrl)
        }).catch(err=>{
            console.log(111,err)
            window.location.replace(returnUrl)
        })
    }
    componentDidMount() {
        const locationQuery = new URLSearchParams(this.props.location.search)
        let returnUrl = decodeURIComponent(locationQuery.get('returnUrl'))
        let messageId = locationQuery.get('messageId')
        this.setNotificationsReadData(messageId,returnUrl)
    }

    render() {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Spin tip="消息加载中..." size="large" style={{fontSize: '18px'}}/>
            </div>
        )
    }
}