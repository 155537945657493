import React, {Component,useState} from 'react'
import {Transfer,Tree} from 'antd';
import {Redirect, Route, Switch, withRouter,useRouteMatch,useLocation} from 'react-router-dom'
import * as Icon from "@ant-design/icons";
import IconFun from '../../public/icon'

class TransferTree extends Component {
    state = {

    }
    handleTransferChange = (targetKey,directions)=>{
        console.log('transferC123',targetKey,directions)
        this.props.handleTransferChange(targetKey,directions,this.props.rowKey)
    }
    handleLeftCheck = (checkedKeys,{node,halfCheckedKeys},checkedAllKeys,onItemSelect)=>{
        console.log(checkedKeys,node,checkedAllKeys)
        const {eventKey } = node
        onItemSelect(eventKey,!(checkedAllKeys.indexOf(eventKey) !== -1))

        this.props.handleLeftCheck(checkedKeys,node,halfCheckedKeys)

    }
    handleRightCheck = (checkedKeys,{node,halfCheckedKeys},checkedAllKeys,onItemSelect)=>{
        console.log(checkedKeys,node,checkedAllKeys)
        const {eventKey } = node
        onItemSelect(eventKey,checkedAllKeys.indexOf(eventKey) !== -1)

        this.handleRightCheck(checkedKeys,node,halfCheckedKeys)

    }
    componentDidMount(){
        console.log('current', this.props);

    }
    render() {
        const {disabled,targetDataKeys,treeData,title,rowKey,leftCheckedKeys,leftTreeData,rightCheckedKeys,rightTreeData} = this.props
        return (
            <>
                <Transfer
                    // disabled={disabled}
                    dataSource={treeData}
                    showSearch={title!=='deptName'}
                    filterOption={(inputValue, option) => {
                        let searchText = (option[title] ?? '') + (option?.pinyin ?? '')
                        // return option[title].indexOf(inputValue) > -1;
                        return searchText.toLowerCase().includes(inputValue.toLowerCase())

                    }}
                    targetKeys={targetDataKeys}
                    onChange={this.handleTransferChange}
                    pagination={title!=='deptName'?true:false}
                    showSelectAll={false}
                    render={(item) => item[title]}
                    rowKey={(item) => item[rowKey]}
                >
                    {
                        title === 'deptName'?
                        ({ direction, onItemSelect, selectedKeys }) => {

                            return (
                                direction == 'left' ?
                                    <Tree
                                        checkable
                                        fieldNames={{children: 'children', title: title, key: rowKey}}
                                        checkedKeys={leftCheckedKeys}
                                        treeData={leftTreeData}
                                        onCheck={(checkedKeys, e) => this.handleLeftCheck(checkedKeys, e, [...selectedKeys, ...targetDataKeys], onItemSelect)}
                                    />
                                    :
                                    <Tree
                                        checkable
                                        fieldNames={{children: 'children', title: title, key: rowKey}}
                                        checkedKeys={rightCheckedKeys}
                                        treeData={rightTreeData}
                                        onCheck={(checkedKeys, e) => this.handleRightCheck(checkedKeys, e, [...selectedKeys, ...targetDataKeys], onItemSelect)}
                                    />

                            )
                        }:''
                    }
                </Transfer>
            </>


        )
    }
}
export default TransferTree