import React, {Component} from 'react'
import {
    Table,
    Button,
    message,
    Tag, Space,
    Drawer, Card, Modal,
} from 'antd';
import {
    getUserPeersByPeriod, copyLastPeriodPeers,
} from '../../request'
import TransferModal from "./TransferModal";
let columsKey = {
    index:"序号",
    PERIOD_NAME: '期次',
    PEERS: 'peers',
    actions: '操作',
}
class SetPeers extends Component {
    employeeModal = React.createRef()
    state = {
        dataSource:[],
        columns:[],
        loading:false,
        visible:false,
        peersDrawerVisible:false,
        currentItem:{},
    };
    createColumns = (createKeys)=>{
        let columns = Object.keys(createKeys).map(item=>{
            let initObj = {
                title:createKeys[item],
                dataIndex:item,
                key:item,
                align:'left'
            }
            if(item=='index'){
                return {
                    ...initObj,
                    align:'center',
                    width:50,
                    render:(text,record,index)=>{
                        return index+1
                    }
                }
            }else if(item=='actions'){
                return {
                    ...initObj,
                    fixed: 'right',
                    width: 200,
                    render: (text, record, index) => {
                        return (
                            <>
                                <Button type="link" size="small" onClick={() => (this.openSetting(record))}>设置peers</Button>
                                <Button type="link" size="small" onClick={() => (this.copyLastPeriod(record))}>复制上月</Button>
                            </>
                        )
                    }
                }
            }else{
                return initObj
            }
        })
        this.setState({
            columns,
        })
    }
    initData(values){
        this.setState({ loading: true });
        getUserPeersByPeriod({userId:values.userId}).then(res=>{
            this.setState({
                loading: false,
                dataSource:res,
            })
        })
    }
    openSetting = (values,tab)=>{
        console.log(values,this.employeeModal)
        this.employeeModal.current.handleModalOpen({...values,...this.state.currentItem,departmentShow:false})
    }
    copyLastPeriod = (values,tab)=>{
        Modal.confirm({
            title: '提示',
            content: `复制上月的peers会覆盖当前的peers，确定要复制吗?`,
            onOk: () => {
                copyLastPeriodPeers({periodId:values.PERIOD_ID,userId:this.state.currentItem.userId}).then(res=>{
                    message.success(res||'复制上月的peers成功')
                    this.initData(this.state.currentItem)
                })
            },
            onCancel: () => {

            }

        })
    }
    onDrawerClose = ()=>{
        this.setState({
            currentItem:{},
            dataSource:[],
            peersDrawerVisible:false,
        })
        this.props.handleDrawerClose()
    }
    handleDrawerOpen = (values)=>{
        console.log(123,values)
        if(values.peerType==2){
            this.setState({
                peersDrawerVisible:true,
                currentItem:values
            })
            this.createColumns(columsKey);
            this.initData(values)
        }else{
            this.employeeModal.current.handleModalOpen({...values,...this.state.currentItem,departmentShow:false})
        }

    }
    handleCloseModal = ()=>{
        this.initData(this.state.currentItem)
    }
    componentDidMount(){
    }
    render() {
        const { dataSource,columns, loading,peersDrawerVisible,} = this.state;

        return (
            <>
                <Drawer
                    destroyOnClose
                    title="按期设置peers"
                    width={720}
                    onClose={this.onDrawerClose}
                    open={peersDrawerVisible}
                >
                    <Table
                        bordered={true}
                        size="small"
                        pagination={false}
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={record=>record.id}
                        loading={loading}/>
                    <TransferModal ref={this.employeeModal} from={'employee'} handleCloseModal={this.handleCloseModal}/>

                </Drawer>
                <TransferModal ref={this.employeeModal} from={'employee'} handleCloseModal={this.handleCloseModal}/>


            </>

        )
    }
}
export default SetPeers