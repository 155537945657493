import React, {Component} from 'react'
import {
    Card,
    Table,
    Button,
    message,
    Tag,
    Tooltip,
    Segmented,Popconfirm,
    Tabs,
    Row,
    Col,
    Steps,
    Form,
    Popover,
    Select,
    Modal,
    Input,
    Collapse,
    PageHeader,
    InputNumber,
    Spin,
    Empty,
    Alert,
    Badge,
    Radio,
    Divider,Descriptions
} from 'antd';
import {
    getSubordinateKpiDetail,
    saveSubordinateKpi,
    ensureSubordinateKpi,returnBackKpiSubordinateKpi,
    publishSubordinateKpi,
    delSurveyProject, clearSelfPerformanceRate,changeKpiMakingType
} from '../../request'
import * as Icon from '@ant-design/icons';
import {withRouter} from "react-router-dom";
import ProcessSteps from "./processSteps"
import './kpi.less'
import SetPeers from "./SetPeers";
const { Panel } = Collapse;
const  locationQuery = new URLSearchParams(window.location.search)
const iFrameParam = locationQuery.get('iframe')
class KpiDetail extends Component {
    setPeersDrawer = React.createRef()
    surveyModal = React.createRef()
    state = {
        dataSource: [],
        dicList: {},
        SurveyQaList: [],
        columns: [],
        loading: false,
        visible: false,
        systemItem: {},
        formParam: {
            userId: this.props.id != 0 ? this.props.id : null,
        },
        total: 0,
        formDataParam: {
            name: ''
        },
        detailDrawerVisible: false,
        submitParam: {},
        userName: '',
        ensure: false,
        tabsItem: [],
        kpiMakingType: false,
        peerType:'1'
    }

    initTableDataList() {
        this.setState({loading: true});
        console.log('ff', this.state.formParam)
        getSubordinateKpiDetail(this.state.formParam).then(res => {
            this.setState({
                loading: false,
                dataSource: res.list,
                defaultActive: res.list.map(item => item.id),
                userName: res.currentUserName || '',
                ensure: res.ensure,
                peerType:res.peerType
            })
        })
    }

    handlePushFilterValueChange = (changedValues, allValues, index) => {
        let param = this.state.dataSource
        param[index].content = allValues.content
        console.log('valueChange', changedValues, allValues)
        this.setState({
            dataSource: param
        })
    }
    handleBlur = (val, index) => {
        console.log('blur', val, index, this.state.dataSource[index])
        this.saveKpi(index)
    }
    clearSelfScore = (item, index) => {
        let modalTitle = '确定要重新指定KPI吗？如果重新制定KPI，员工需要重新进行KPI确认及自评，请谨慎选择'
        Modal.confirm({
            title: '提示',
            content: modalTitle,
            onOk: () => {
                clearSelfPerformanceRate({userId: this.state.formParam.userId, periodId: item.periodId}).then(res => {
                    message.success('成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })

    }
    saveKpi = (index) => {
        saveSubordinateKpi([this.state.dataSource[index]]).then(res => {
            message.success('草稿保存成功')
            this.initTableDataList()
        })
    }
    publishKpi = (index) => {
        Modal.confirm({
            title: '提示',
            content: '确认要发布KPI吗？发布之后员工就可以开始自评，且员工自评之后无法修改KPI内容，请谨慎选择！',
            onOk: () => {
                publishSubordinateKpi({id: this.state.dataSource[index].id}).then(res => {
                    message.success('发布成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })


    }
    toPerformance = (index, type) => {
        let periodId = this.state.dataSource[index].periodId
        let url = type == 'self' ? '/oa/myperformance' : `/oa/subordinate/performancedetail/${this.state.formParam.userId}`
        this.props.history.push({
            pathname: url,
            search: `?periodId=${periodId}&kpiType=${this.state.formParam.kpiType}`
        })
    }
    ensureKpi = (id) => {
        ensureSubordinateKpi({kpiId: id}).then(res => {
            message.success('确认成功')
            this.initTableDataList()
        })
    }
    returnBackKpi = (id) => {
        Modal.confirm({
            title: '提示',
            content: '确认退回该员工KPI吗？',
            onOk: () => {
                returnBackKpiSubordinateKpi({id: id}).then(res => {
                    message.success('退回成功')
                    this.initTableDataList()
                })
            },
            onCancel: () => {

            }

        })

    }
    setPeers = () => {
        this.setPeersDrawer.current.handleDrawerOpen({id:this.state.formParam.classId,userId:this.state.formParam.userId,departmentShow:false,peerType:this.state.peerType})
    }
    handleCloseModal = ()=>{
    }
    handleTabChange = (key) => {
        console.log(key)
        this.setState({
            formParam: {
                ...this.state.formParam,
                kpiType: key,
            },
            kpiMakingType: key == 1 ? false : true,
            dataSource: []
        }, () => {
            this.initTableDataList()
        })
    }
    handleSetKpiChange = (key) => {
        console.log(key)
        this.setState({
            kpiMakingType: key.target.value
        })
    }
    handleEditSetKpi = () => {
        changeKpiMakingType({
            userId: this.state.formParam.userId,
            kpiMakingType: this.state.kpiMakingType,
            kpiType: this.state.formParam.kpiType
        }).then(res => {
            message.success('修改成功')
            this.initTableDataList()
        })
    }

    componentDidMount() {
        // const locationQuery = new URLSearchParams(this.props.location.search)

        const initType = locationQuery.get('kpiType')
        let sList = [
            {
                label: '新人之旅Kpi',
                key: '1',
            },
            {
                label: '月度Kpi',
                key: '2'
            },
        ]
        const tabsItem = initType ? sList.filter(item => item.key == initType) : sList
        const kpiType = initType || '1'
        console.log(456, tabsItem)
        this.setState({
            formParam: {
                ...this.state.formParam,
                kpiType,
            },
            tabsItem: [...tabsItem],
            kpiMakingType: kpiType == 1 ? false : true
        }, () => {
            console.log(this.state.tabsItem)
            this.initTableDataList()
        })
    }

    render() {
        // const locationQuery = new URLSearchParams(this.props.location.search)

        const periodId = locationQuery.get('periodId')
        const {dataSource, columns, loading, detailDrawerVisible, dicList, editParam} = this.state;
        return (
            <>
                <Card title={

                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <div style={{marginRight: '50px'}}>
                            {this.props.id ?
                                <>
                                    {
                                        iFrameParam != 'iframe' ?
                                            <span style={{marginRight: '10px', fontWeight: 'bolder', cursor: 'pointer'}}
                                                  onClick={() => this.props.history.go(-1)}><Icon.ArrowLeftOutlined/></span>
                                            : ''
                                    }
                                    {
                                        'KPI设置：' + this.state.userName
                                    }
                                </> :
                                'KPI设置：我的'
                            }
                        </div>
                        <Tabs
                            onChange={(key) => this.handleTabChange(key)}
                            items={this.state.tabsItem}
                        />

                    </div>
                } bordered={false}
                      className={'card-fixed-head-wrap kpi-detail-wrap'}
                >
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
                        {this.props.id ?
                            <>
                                {
                                    this.state.formParam.kpiType == 2 ?
                                        <Popover
                                            content={
                                                <>
                                                    <Alert style={{marginBottom: '20px'}}
                                                           message={'只有月度KPI才可以修改「KPI制定方式」,且修改之后下一期生效'} showIcon
                                                           type="warning"/>
                                                    <Radio.Group onChange={(val) => this.handleSetKpiChange(val)}
                                                                 size="large" defaultValue={this.state.kpiMakingType}
                                                                 buttonStyle="solid">
                                                        <Radio value={false}>Leader制定KPI</Radio>
                                                        <Radio
                                                            disabled={this.state.formParam.kpiType == 2 ? false : true}
                                                            value={true}>员工自填KPI</Radio>
                                                    </Radio.Group>

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        marginTop: '20px'
                                                    }}>
                                                        {
                                                            this.state.formParam.kpiType == 2 ?
                                                                <Tag style={{cursor: 'pointer'}} color={'pink'}
                                                                     onClick={() => this.handleEditSetKpi()}>确定</Tag> : ''
                                                        }
                                                    </div>

                                                </>
                                            }
                                            title="KPI制定方式"
                                            trigger="click"
                                        >
                                            <Button type={'primary'} size={'small'} style={{margin: '0 5px 0 10px'}}
                                            >设置KPI制定方式</Button>
                                        </Popover> : ''
                                }

                                <Button type={'primary'} size={'small'} style={{margin: '0 0 0 5px'}} danger
                                        onClick={this.setPeers}>设置360环评人员</Button>
                            </>
                            : ''
                        }
                    </div>

                    <Spin spinning={loading}>
                        {
                            this.state.ensure ?
                                <div style={{textAlign: 'right', marginBottom: '20px'}}><Button type={'primary'}
                                                                                                onClick={() => this.ensureKpi(null)}>确认KPI</Button>
                                </div> : ''
                        }
                        {
                            this.state.dataSource.length > 0 ?
                                <Collapse collapsible="header" defaultActiveKey={[periodId]} expandIconPosition={'end'}>
                                    {
                                        this.state.dataSource.map((item, listIndex) => {
                                            return (
                                                <Panel forceRender header={
                                                    <Tooltip title={'当前周期的进度为：' + item.stage} placement={'topLeft'}>
                                                        <div style={{paddingLeft: '0px', paddingTop: '6px'}}>

                                                            <Badge.Ribbon placement={'start'} text={item.stage.split('(')[0]}
                                                                          color="#54a6f1"/>

                                                            {item.periodName}
                                                            <Badge count={item.makingType} showZero color="#faad14"/>
                                                        </div>
                                                    </Tooltip>
                                                }
                                                       extra={<ProcessSteps steps={item.stageVO}/>}
                                                       key={item.periodId}>

                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: 'space-between',
                                                        marginBottom: "20px"
                                                    }}>
                                                        <div>
                                                            {
                                                                item.showPublishTip ?
                                                                    <span style={{fontSize: '11px', color: '#e50011'}}>
                                                                        <Icon.InfoCircleOutlined/> 当前状态为草稿状态，请记得设置完整Kpi后， 点击「发布KPI」
                                                                        </span>
                                                                    : ''
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                item.edit && !item.subEnsure ?
                                                                    <>
                                                                        <Tag style={{cursor: 'pointer'}}
                                                                             onClick={() => this.publishKpi(listIndex)}
                                                                             color={'red'}>发布KPI</Tag>
                                                                    </> : ''
                                                            }{
                                                            item.showTurnBack ?
                                                                <>
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         onClick={() => this.returnBackKpi(item.id)}
                                                                         color={'orange'}>退回KPI</Tag>
                                                                </> : ''
                                                        }{
                                                            item.subEnsure ?
                                                                <>
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         onClick={() => this.ensureKpi(item.id)}
                                                                         color={'purple'}>确认KPI</Tag>
                                                                </> : ''
                                                        }
                                                            {item.showClearSub ?
                                                                <Tag style={{cursor: 'pointer'}}
                                                                     onClick={() => this.clearSelfScore(item, listIndex)}
                                                                     color={'pink'}>重新制定</Tag>
                                                                : ''}
                                                            {
                                                                item.leaderEvaluate ?
                                                                    <Tag style={{cursor: 'pointer'}}
                                                                         color={'green'}
                                                                         onClick={() => this.toPerformance(listIndex, 'leader')}>评分</Tag> : ''
                                                            }
                                                            {
                                                                item.selfEvaluate ? <Tag color={'blue'}
                                                                                         style={{cursor: 'pointer'}}
                                                                                         onClick={() => this.toPerformance(listIndex, 'self')}>自评</Tag> : ''
                                                            }
                                                        </div>

                                                    </div>
                                                    <Form initialValues={item}
                                                          disabled={!item.edit}
                                                          labelCol={{flex: '60px'}}
                                                          onValuesChange={(changedValues, allValues) => this.handlePushFilterValueChange(changedValues, allValues, listIndex)}
                                                    >
                                                        <Form.Item hidden name='id'>
                                                            <input/>
                                                        </Form.Item>
                                                        <Form.List name='content'>
                                                            {
                                                                (fields, operation) => {
                                                                    return (
                                                                        <div>
                                                                            {(fields || []).map((field, index) => {
                                                                                return (
                                                                                    <div key={field.key}>

                                                                                        <Form.List
                                                                                            name={[field.name, 'groupContents']}>
                                                                                            {
                                                                                                (groupContents, groupOperation) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Form.Item>
                                                                                                                <Alert
                                                                                                                    message={item.content[index].groupName}
                                                                                                                    type="success"
                                                                                                                    action={
                                                                                                                        <Tag
                                                                                                                            style={{cursor: 'pointer'}}
                                                                                                                            color={'#87d068'}
                                                                                                                            onClick={() => groupOperation.add()}>
                                                                                                                            <Icon.PlusOutlined/> 添加
                                                                                                                        </Tag>
                                                                                                                    }
                                                                                                                    showIcon/>
                                                                                                            </Form.Item>
                                                                                                            {
                                                                                                                (groupContents || []).map((group, groupIndex) => {
                                                                                                                    return (<>
                                                                                                                            <Descriptions
                                                                                                                                className="group-descriptions"
                                                                                                                                size="small"
                                                                                                                                labelStyle={{
                                                                                                                                    width: '45px',
                                                                                                                                    padding: '4px',
                                                                                                                                    textAlign: 'center'
                                                                                                                                }}
                                                                                                                                contentStyle={{padding: '4px'}}
                                                                                                                                bordered
                                                                                                                                column={{
                                                                                                                                    xxl: 4,
                                                                                                                                    xl: 4,
                                                                                                                                    lg: 4,
                                                                                                                                    md: 2,
                                                                                                                                    sm: 2,
                                                                                                                                    xs: 1
                                                                                                                                }}>
                                                                                                                                <Descriptions.Item
                                                                                                                                    label="内容">
                                                                                                                                    <Form.Item
                                                                                                                                        hidden
                                                                                                                                        name={[group.name, 'contentGroup']}>
                                                                                                                                        <Input/>
                                                                                                                                    </Form.Item>
                                                                                                                                    <Form.Item
                                                                                                                                        name={[group.name, 'content']}>
                                                                                                                                        <Input.TextArea
                                                                                                                                            autoSize={{
                                                                                                                                                minRows: 1,
                                                                                                                                                maxRows: 4
                                                                                                                                            }}
                                                                                                                                            onBlur={(val) => this.handleBlur(val, listIndex)}/>
                                                                                                                                    </Form.Item>
                                                                                                                                </Descriptions.Item>
                                                                                                                                <Descriptions.Item
                                                                                                                                    label="权重"
                                                                                                                                    contentStyle={{width: '80px'}}>
                                                                                                                                    <Form.Item
                                                                                                                                        name={[group.name, 'rate']}>
                                                                                                                                        <InputNumber
                                                                                                                                            placeholder={'0'}
                                                                                                                                            onBlur={(val) => this.handleBlur(val, listIndex)}/>
                                                                                                                                    </Form.Item>
                                                                                                                                </Descriptions.Item>
                                                                                                                                {
                                                                                                                                    item.standardList.length > 0 ?
                                                                                                                                        <Descriptions.Item
                                                                                                                                            label="标准"
                                                                                                                                            contentStyle={{width: '145px'}}>
                                                                                                                                            <Form.Item
                                                                                                                                                name={[group.name, 'evaluateStandard']}>
                                                                                                                                                <Select
                                                                                                                                                    style={{width: '100%'}}
                                                                                                                                                    showSearch
                                                                                                                                                    placeholder="请选择"
                                                                                                                                                    optionFilterProp="children"
                                                                                                                                                    fieldNames={{
                                                                                                                                                        label: 'name',
                                                                                                                                                        value: 'value'
                                                                                                                                                    }}
                                                                                                                                                    filterOption={(input, option) =>
                                                                                                                                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                                                                                                                    }
                                                                                                                                                    onSelect={(val) => this.handleBlur(val, listIndex)}
                                                                                                                                                    options={item.standardList}
                                                                                                                                                />
                                                                                                                                            </Form.Item>

                                                                                                                                        </Descriptions.Item>
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    item.edit ?
                                                                                                                                        <Descriptions.Item
                                                                                                                                            label={'操作'}
                                                                                                                                            contentStyle={{width: '50px'}}
                                                                                                                                        >
                                                                                                                                            <Popconfirm
                                                                                                                                                title="确定要删除这条KPI吗?"
                                                                                                                                                onConfirm={() => {
                                                                                                                                                    groupOperation.remove(groupIndex)
                                                                                                                                                    this.handleBlur({}, listIndex)
                                                                                                                                                }}
                                                                                                                                                okText="Yes"
                                                                                                                                                cancelText="No"
                                                                                                                                            >
                                                                                                                                                <div
                                                                                                                                                    className="close-filter">
                                                                                                                                                    <Icon.DeleteOutlined/>
                                                                                                                                                </div>
                                                                                                                                            </Popconfirm>

                                                                                                                                        </Descriptions.Item>
                                                                                                                                        : ''
                                                                                                                                }
                                                                                                                            </Descriptions>
                                                                                                                            {/*<Row gutter={10} align={{xs:'bottom',sm:'top'}}>*/}
                                                                                                                            {/*    <Col xs={24} sm={24} md={24} lg={24}>*/}
                                                                                                                            {/*        <Form.Item*/}
                                                                                                                            {/*            label={'内容'}*/}
                                                                                                                            {/*            hidden*/}
                                                                                                                            {/*            name={[group.name, 'contentGroup']}>*/}
                                                                                                                            {/*            <Input/>*/}
                                                                                                                            {/*        </Form.Item>*/}
                                                                                                                            {/*        <Form.Item*/}
                                                                                                                            {/*            label={'内容'}*/}
                                                                                                                            {/*            name={[group.name, 'content']}>*/}
                                                                                                                            {/*            <Input.TextArea*/}
                                                                                                                            {/*                autoSize={{*/}
                                                                                                                            {/*                    minRows: 1,*/}
                                                                                                                            {/*                    maxRows: 4*/}
                                                                                                                            {/*                }}*/}
                                                                                                                            {/*                onBlur={(val) => this.handleBlur(val, listIndex)}/>*/}
                                                                                                                            {/*        </Form.Item>*/}
                                                                                                                            {/*    </Col>*/}
                                                                                                                            {/*    <Col xs={24} sm={24} md={10} lg={6}>*/}
                                                                                                                            {/*        <Form.Item*/}
                                                                                                                            {/*            label={'权重'}*/}
                                                                                                                            {/*            name={[group.name, 'rate']}>*/}
                                                                                                                            {/*            <InputNumber*/}
                                                                                                                            {/*                onBlur={(val) => this.handleBlur(val, listIndex)}/>*/}
                                                                                                                            {/*        </Form.Item>*/}
                                                                                                                            {/*    </Col>*/}
                                                                                                                            {/*    {*/}
                                                                                                                            {/*        item.standardList.length > 0 ? (*/}
                                                                                                                            {/*            <Col sm={24} md={12} lg={6}>*/}
                                                                                                                            {/*                <Form.Item*/}
                                                                                                                            {/*                    label={'标准'}*/}
                                                                                                                            {/*                    name={[group.name, 'evaluateStandard']}>*/}
                                                                                                                            {/*                    <Select*/}
                                                                                                                            {/*                        style={{width: '100%'}}*/}
                                                                                                                            {/*                        showSearch*/}
                                                                                                                            {/*                        placeholder="请选择"*/}
                                                                                                                            {/*                        optionFilterProp="children"*/}
                                                                                                                            {/*                        fieldNames={{*/}
                                                                                                                            {/*                            label: 'name',*/}
                                                                                                                            {/*                            value: 'value'*/}
                                                                                                                            {/*                        }}*/}
                                                                                                                            {/*                        filterOption={(input, option) =>*/}
                                                                                                                            {/*                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                                                                                                            {/*                        }*/}
                                                                                                                            {/*                        onSelect={(val) => this.handleBlur(val, listIndex)}*/}
                                                                                                                            {/*                        options={item.standardList}*/}
                                                                                                                            {/*                    />*/}
                                                                                                                            {/*                </Form.Item>*/}
                                                                                                                            {/*            </Col>*/}
                                                                                                                            {/*        ) : ''*/}
                                                                                                                            {/*    }*/}

                                                                                                                            {/*    <Col  sm={24} lg={2} className={'close-filter-wrap'}>*/}
                                                                                                                            {/*        {*/}
                                                                                                                            {/*            item.edit ?*/}
                                                                                                                            {/*                <div*/}
                                                                                                                            {/*                    className="close-filter"*/}
                                                                                                                            {/*                    onClick={() => {*/}
                                                                                                                            {/*                        groupOperation.remove(groupIndex)*/}
                                                                                                                            {/*                        this.handleBlur({}, listIndex)*/}
                                                                                                                            {/*                    }}>*/}
                                                                                                                            {/*                    <Icon.MinusCircleOutlined/>*/}
                                                                                                                            {/*                </div>*/}
                                                                                                                            {/*                : ''*/}
                                                                                                                            {/*        }*/}
                                                                                                                            {/*    </Col>*/}

                                                                                                                            {/*</Row>*/}
                                                                                                                            {/*    <Divider style={{margin: '3px 0 10px'}} dashed />*/}
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )

                                                                                                }
                                                                                            }
                                                                                        </Form.List>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                        </Form.List>
                                                    </Form>

                                                </Panel>
                                            )
                                        })
                                    }
                                </Collapse>
                                :
                                <Empty/>
                        }

                    </Spin>
                </Card>
                <SetPeers ref={this.setPeersDrawer} handleDrawerClose={this.handleCloseModal}></SetPeers>

            </>

        )
    }
}
export default withRouter(KpiDetail)