import React, {Component,useState} from 'react'
import {Calendar, PageHeader, Badge, Tooltip, Popover, Button} from 'antd';
import { UserOutlined, LockOutlined,InstagramOutlined,CodepenOutlined } from '@ant-design/icons';
import {Redirect, Route, Switch} from 'react-router-dom'
import {envHttpUrl, getmd5} from '../../http/globalMethod'
import {getCalendarDataList, loginOutRequest} from '../../request'
import * as Icon from "@ant-design/icons";
import './Calendar.less'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default class CalendarComponent extends Component {
    state = {
        dateListObj:{},
        curDate:moment(new Date())
    }
    toPage = (url)=>{
        console.log(url)
        this.props.history.push(url)
    }
    initCalendarDataList = (curDate)=>{
        getCalendarDataList({date:curDate}).then(res=>{
            let dateObj = {}
            res.map(item=>{
                dateObj[item.date] = item.list
            })
            this.setState({
                dateListObj:dateObj
            })
        })
    }
    dateCellRender = (value)=> {
        let curDate = value.format('YYYY-MM-DD')
        let dataList = this.state.dateListObj[curDate]
        return (
            <div>
                {(dataList || []).map(item => {
                    return (
                        <Popover trigger={'hover'} title={
                            <span
                                style={{textDecoration: item.status == 'cancelled' ? 'line-through' : ''}}>{item.status == 'cancelled' ? '[已取消] ' : ''}{item.summary}</span>
                        } content={
                            <>
                                <p>时间: {item.beginTime} ~ {item.endTime}</p>
                                <p>创建人: {item.createdBy}</p>
                                {
                                    item.local ? <p>地点: {item.local}</p> : ''
                                }
                                {
                                    item.peers && item.peers.length > 0 ?
                                        <p>参与者: {item.peers.map(item1 => item1.displayName).join('，')}</p> : ''
                                }
                                {
                                    item.url ? <p>打开链接：<Button type={'primary'} onClick={
                                        () => {
                                            window.open(item.url, '_blank')
                                        }
                                    }>跳转</Button></p> : ''
                                }

                            </>
                        }>
                            <div style={{
                                margin: '0 0 10px',
                                fontSize: '12px',
                                display: 'flex',
                                alignContent: 'center',
                                lineHeight: 1
                            }}>
                                <Badge color={item.status == 'cancelled' ? '' : "green"}
                                       status={item.status == 'cancelled' ? 'default' : "processing"}/>
                                <div style={{
                                    marginLeft: '5px',
                                    overflow: 'hidden',
                                    whiteSpace: "nowrap",
                                    textOverflow: 'ellipsis',
                                    textDecoration: item.status == 'cancelled' ? 'line-through' : ''
                                }}>
                                    {item.beginTime} {item.summary}
                                </div>
                            </div>
                        </Popover>
                    )

                })}
            </div>
        )
    }
    handleChange = (val,info)=>{
        console.log(val,info)
        if(info=='mouth'){
            this.setState({
                curDate:moment(new Date())
            })
        }
        let curDate = val.format('YYYY-MM-DD')
        this.initCalendarDataList(curDate)
    }
    handleSelect = (val,event)=>{
        let curDate = val.format('YYYY-MM-DD')
        this.setState({
            curDate:moment(new Date())
        })
        // this.initCalendarDataList(curDate)
    }
    componentDidMount(){
        let curDate = moment(new Date()).format('YYYY-MM-DD')
        console.log(curDate)
        this.initCalendarDataList(curDate)
    }
    render() {
        return (
                <div className={'calendar-full-wrap'} style={{padding:'20px'}}>
                    <Calendar value={this.state.curDate}
                              onChange={(val)=>this.handleSelect(val,event)}
                              dateCellRender={(val)=>this.dateCellRender(val)}/>
                </div>

        )
    }
}
