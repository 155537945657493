import React, {Component} from 'react'
import {
    Input,
    Card,
    Row,
    Col,
    message,
    Modal,
    Tag,
    Avatar,
    Button,
    PageHeader,
    Tabs,
    List,
    Badge,
    Space, Typography,Spin, Drawer
} from 'antd';
import {
    getBaseSystem,
    lcBiSystemLogin,
    lcBiSystemLoginSubmit,
    getMyCollectData,
    getNotificationsList,
    handleNotifications,
    handleLinkNotifications,
    getMaterialCheckList,
    getNotificationsCount,
    getAnnouncementList
} from '../../request'
import IconFun from '../../public/icon'
import {envHttpUrl} from '../../http/globalMethod'

import './index.less'
import SuggestModal from "../../components/OA/suggestModal";
export default class PortalSystem extends Component {
    suggestModalRef = React.createRef()

    state = {
        formParam: {
            pageSize: 2000,
            pageNum: 1,
            isRead: 0
        },
        noticeList:[],
        helpsList:[],
        sysUiList:[
            {color:'cyan'},
            {color:'pink'},
            {color:'geekblue'},
            {color:'red'},
            {color:'green'},
            {color:'volcano'},
            {color:'purple'},
            {color:'orange'},
            {color:'gold'},
            {color:'blue'},
            {color:'blue'},
            {color:'pink'},
            {color:'pink'},
        ],
        systemData:[],
        collectData:[],
        notifications:{
            oaList:[],
            csmsList:[],
            productList:[],
            erpList:[],
            scrmList:[],
            sysList:[],
        },
        loading:false,
        checkLoading:false,
        unCheckMaterialCount:0,
        unReadCount:0,
        todayReadCount:0,
        todayUnReadCount:0,
        notificationsList:[],
        notificationsCsmsList:[],
        notificationsProList:[],
        materialCheckList:[],
        isModalOpen:false,
        titleMessage:'请设置密码',
        password:'',
        logoImg:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg',
        checkMaterialVisible:false,
        checkMaterialUrl:''
    }
    getSystemData = ()=>{
        getBaseSystem().then(res=>{
            console.log(res)
            this.setState({
                systemData:res||[]
            })
        })
    }
    getPortalMessageCount = ()=>{
        getNotificationsCount().then(res=>{
            this.setState({
                unReadCount: res.message.unReadCount,
                todayUnReadCount: res.message.todayUnReadCount,
                todayReadCount: res.message.todayReadCount,
            })
        })
    }
    getAnnouncementListData(){
        getAnnouncementList({pageSize: 1000,currentPage:1,status:1}).
        then(res=>{
            this.setState({
                noticeList:res.list.filter(item=>item.type==1),
                helpsList:res.list.filter(item=>item.type==2),
            })
        })
    }
    getNotificationsListData = (source)=>{
        return new Promise((resolve)=>{
            getNotificationsList({...this.state.formParam,messageSource: 'lechun-'+source}).then(res=>{
                console.log(res)
                let paramList = {...this.state.notifications}
                paramList[source+'List'] = res.list
                this.setState({
                    notifications:paramList
                })
                resolve(res)
            })
        })
    }
    getCollectData = ()=>{
        getMyCollectData().then(res=>{
            console.log(res)
            this.setState({
                collectData:res.list||[]
            })
        })
    }
    getMaterialCheckData = ()=>{
        getMaterialCheckList().then(res=>{
            console.log(res)
            this.setState({
                materialCheckList:res||[],
                unCheckMaterialCount:res.length
            })
        })
    }
    toSystemPage = (item)=>{
        console.log(item)
        let resourceHtml = window.location.protocol+'//'+item.resourceHtmlUrl.split('//')[1]
        let routeParam = {
            pathname:'/portal/lcpagesystem',
            search:'url='+encodeURIComponent(resourceHtml)
        }
        if(item.serviceId=='LECHUN-BI'){
            lcBiSystemLogin().then(res=>{
                console.log(res)
                if(res.success==0){
                    this.setState({
                        isModalOpen:true,
                        titleMessage:res.message
                    })
                }else{
                    this.props.history.push(routeParam)
                }
            })
        }else if(item.serviceId=='GUANYUAN-BI'){
            this.props.history.push('/portal/guanyuanbi')
        }else if(item.serviceId=='LECHUN-AMS'){
            this.props.history.push('/admin/dashboard')
        }else if(item.serviceId=='LECHUN-OA'){
            this.props.history.push('/oa/dashboard')
        }else{
            this.props.history.push(routeParam)

        }

    }
    handleLcBiSubmit(){
        console.log(this.state.password)
        lcBiSystemLoginSubmit({password:this.state.password}).then(res=>{
            message.success('成功')
            this.setState({
                isModalOpen:false,
            })
            let biUrl = 'http://bi.lechun.cc'
            // window.location.href = biUrl
            window.open(biUrl,'_blank')
        })
    }
    handleLcBiCancel(){
        this.setState({
            isModalOpen:false,
        })
    }
    handleInputPassword(e){
        console.log(e.currentTarget.value,this)
        this.setState({
            password:e.currentTarget.value
        })
    }

    handleMessage = (item,nId)=>{
        console.log(item)
        handleNotifications({messageUserId:nId,actionId:item.id}).then(res=>{
            if(item.buttonAction==1){
                window.open(item.buttonUrl,'_blank')
            }else if(item.buttonAction==2){
                handleLinkNotifications(item.buttonUrl).then(res1=>{
                    message.success('success')
                })
            }
            this.loadMessageList()

        })
    }

    handleCheckMaterial = (item)=>{
        console.log(item)
        this.setState({
            checkLoading:true,
            checkMaterialVisible:true,
            checkMaterialUrl:`${window.location.protocol}${envHttpUrl().webUrl}/materialcheckdetail/${item.id}`
        })
        setTimeout(()=>{
            this.setState({
                checkLoading:false
            })
        },2000)

    }
    closeCheckMaterial = (item)=>{
        console.log(item)
        this.setState({
            checkMaterialVisible:false,
        })
        this.getMaterialCheckData()

    }
    notificationsTab = (tabName,key,itemList)=>{
        return (
            {
                label: <span>{tabName}（{itemList.length}）</span>,
                key: key,
                children: <Spin spinning={this.state.loading}><div style={{height: '450px', overflowY: 'scroll'}}>
                    <List
                        size="small"
                        dataSource={itemList}
                        renderItem={(item) => (
                            <List.Item key={item.email}>
                                <List.Item.Meta
                                    title={
                                        <Badge status="processing" color="green" text={
                                            <span style={{
                                                color: '#666',
                                                fontSize: '14px'
                                            }}>{item.messageTitle}</span>
                                        }/>
                                    }
                                    description={
                                        <span style={{
                                            paddingLeft: '15px',
                                            color: '#999',
                                            fontSize: '12px'
                                        }}>时间：{item.messageTime}</span>
                                    }
                                />
                                {
                                    item.actions.length > 0 ?
                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Space>
                                                {
                                                    item.actions.map(item1 => {
                                                        return <Button key={item1.messageUserId}
                                                                       onClick={() => this.handleMessage(item1, item.messageUserId)}
                                                                       size={'small'} type={'primary'}
                                                                       style={{fontSize: '12px'}}>{item1.buttonText}</Button>
                                                    })
                                                }
                                            </Space>

                                        </div>
                                        : ''

                                }
                            </List.Item>
                        )}
                    />
                </div></Spin>,
            }
        )
    }
    loadMessageList(){
        console.log(this)
        this.getPortalMessageCount()
        this.setState({
            loading:true
        })
        this.getNotificationsListData('oa').then(res=>{
            this.setState({
                loading:false
            })
        })
        this.getMaterialCheckData()
        this.getNotificationsListData('csms')
        this.getNotificationsListData('erp')
        this.getNotificationsListData('product')
        this.getNotificationsListData('sys')
        this.getNotificationsListData('scrm')
    }
    handleOpenHelp = (item)=>{
        if(item.announcementLink){
            window.open(item.announcementLink,'_blank')
        }

    }
    handleHelp = ()=>{
        this.suggestModalRef.current.handleOpenModal()
    }
    componentDidMount(){
        this.getSystemData()
        this.getCollectData()
        this.getAnnouncementListData()
        this.loadMessageList()

    }
    render() {
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="乐纯管理系统"
                    subTitle=""
                />
                <div className='portal-dashboard-wrap'>
                    <Row gutter={[20,20]} align={'center'} style={{marginBottom:'20px'}}>
                        <Col xs={{ span: 24}} sm={{ span: 12}} md={{span:6}}>
                            <div className={'tab-item'}>
                                <span className={'item-icon'} style={{backgroundColor:'#f7c94d'}}><IconFun icon={'ClusterOutlined'} /></span>
                                <h3>{this.state.unReadCount+this.state.unCheckMaterialCount}</h3>
                                <p>所有未读消息</p>
                            </div>

                        </Col><Col xs={{ span: 24}} sm={{ span: 12}} md={{span:6}}>
                            <div className={'tab-item'}>
                                <span className={'item-icon'} style={{backgroundColor:'#41b883'}}><IconFun icon={'DashboardOutlined'} /></span>
                                <h3>{this.state.todayUnReadCount}</h3>
                                <p>今日未读</p>
                            </div>

                        </Col><Col xs={{ span: 24}} sm={{ span: 12}} md={{span:6}}>
                            <div className={'tab-item'}>
                                <span className={'item-icon'} style={{backgroundColor:'#e45f5f'}}><IconFun icon={'ApartmentOutlined'} /></span>
                                <h3>{this.state.todayReadCount||0}</h3>
                                <p>今日已读</p>
                            </div>

                        </Col><Col xs={{ span: 24}} sm={{ span: 12}} md={{span:6}}>
                            <div className={'tab-item'}>
                                <span className={'item-icon'} style={{backgroundColor:'#9ab7e0'}}><IconFun icon={'BuildOutlined'} /></span>
                                <h3>{this.state.unCheckMaterialCount||0}</h3>
                                <p>SOP素材未审核</p>
                            </div>

                        </Col>

                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} >
                            <Card title="系统入口" size={'small'} className={'dashboard-item'}>
                                <Row gutter={[10,10]} className='portal-sys' justify={{xl:'left',xxl:'space-between'}}>
                                    {this.state.systemData.map((item,index)=>{
                                        let icon = item.resourceIcon?item.resourceIcon:''
                                        return (
                                            <Col xs={8} sm={6} md={6} lg={4} xl={3} xxl={2} key={item.resourceId}>
                                                <div className={'sys-item'} key={item.resourceId} onClick={()=>this.toSystemPage(item)}>
                                                    {/*<span className='sys-icon'><IconFun icon={icon}/></span>*/}
                                                    <Tag color={this.state.sysUiList[index].color} className='sys-icon'><IconFun icon={icon}/></Tag>
                                                    <h2>{item.resourceName}</h2>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Card>

                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={18} >
                            <Card title="系统未读消息" size={'small'} className={'dashboard-item'} extra={
                                <>
                                    <Tag color={'pink'} style={{cursor:'pointer'}} onClick={()=>this.loadMessageList()}><IconFun icon={'RedoOutlined'} /> 刷新一下</Tag>
                                </>
                                }>
                                <Tabs
                                    size="small"
                                    defaultActiveKey="1"
                                    items={[
                                        this.notificationsTab('OA消息','1',this.state.notifications.oaList),
                                        {
                                            label: <span>SOP素材审核（{this.state.materialCheckList.length}）</span>,
                                            key: '2',
                                            children:
                                                <div style={{height: '450px', overflowY: 'scroll'}}>
                                                <List
                                                    size="small"
                                                    dataSource={this.state.materialCheckList}
                                                    renderItem={(item) => (
                                                        <List.Item key={item.materailId}>
                                                            <List.Item.Meta
                                                                title={
                                                                    <Badge status="processing" color="green" text={
                                                                        <span style={{
                                                                            color: '#666',
                                                                            fontSize: '14px'
                                                                        }}>{item.materialName}</span>
                                                                    }/>
                                                                }
                                                                description={
                                                                    <span style={{
                                                                        paddingLeft: '15px',
                                                                        color: '#999',
                                                                        fontSize: '12px'
                                                                    }}>时间：{item.createTime}</span>
                                                                }
                                                            />
                                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                <Button danger onClick={
                                                                    () => this.handleCheckMaterial(item)}
                                                                    size={'small'} type={'primary'}
                                                                    style={{fontSize: '12px'}}>审核</Button>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>,
                                        },
                                        this.notificationsTab('售后消息','3',this.state.notifications.csmsList),
                                        this.notificationsTab('scrm消息','7',this.state.notifications.scrmList),
                                        this.notificationsTab('产品消息','4',this.state.notifications.productList),
                                        this.notificationsTab('ERP消息','5',this.state.notifications.erpList),
                                        this.notificationsTab('基础系统消息','6',this.state.notifications.sysList),
                                    ]}
                                />
                            </Card>

                        </Col>
                        <Col xs={24} sm={6} style={{width: '200px', display:'flex',flexDirection:'column'}}>
                            <Card size={'small'} title="公告" className={'dashboard-item'}>
                                <div style={{height: '156px', overflowY: 'scroll'}}>
                                    {
                                        this.state.noticeList.map((item,index) => {
                                            return <p key={index} style={{display:'flex'}}><Tag>{item.tag}</Tag>
                                                <Typography.Text
                                                    ellipsis={{
                                                        tooltip: item.title,
                                                    }}>{item.title}</Typography.Text></p>
                                        })
                                    }
                                </div>

                            </Card>
                            <Card size={'small'} title="使用帮助" className={'dashboard-item'}
                            extra={<Tag style={{cursor: 'pointer'}} color={'green'} onClick={()=>this.handleHelp()}><IconFun icon={'CommentOutlined'}/> 反馈及建议</Tag>}>
                                <div style={{height: '96px', overflowY: 'scroll'}}>
                                {
                                    this.state.helpsList.map((item,index) => {
                                        return <p key={index} style={{cursor: 'pointer', color: '#1890ff'}} onClick={() => this.handleOpenHelp(item)}>{item.title}</p>
                                    })
                                }
                                </div>
                            </Card>
                            <Card title="常用页面收藏" size={'small'} className={'dashboard-item'}>
                                <Space wrap={true} size={15} style={{maxHeight:'88px',overflowY:'scroll'}}>
                                    {
                                        this.state.collectData.map(item=>{
                                            return(
                                                <div onClick={()=>{
                                                    window.open(item.pagePath,'_blank')
                                                }}>
                                                    <Avatar shape="square" size={20} src={this.state.logoImg} />
                                                    <span style={{cursor:'pointer',marginLeft:'8px',color:'#1890ff'}}>{item.pageTitle}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </Space>
                            </Card>

                        </Col>
                    </Row>
                </div>

                <Modal
                    width="570px"
                    destroyOnClose={true}
                    title={this.state.titleMessage}
                    open={this.state.isModalOpen} onOk={()=>this.handleLcBiSubmit()} onCancel={this.handleLcBiCancel}>
                    <Input.Password placeholder="请输入密码" onChange={(e)=>this.handleInputPassword(e)} />
                </Modal>
                <Drawer title="素材审核" width={'70%'} placement="right" onClose={() => this.closeCheckMaterial()} open={this.state.checkMaterialVisible}>
                        <iframe src={this.state.checkMaterialUrl} width={'100%'} height={'100%'} frameBorder={'0'}>
                            <Spin style={{width:'100%',height:'100%'}} spinning={this.state.checkLoading}>
                            </Spin>
                        </iframe>

                </Drawer>
                <SuggestModal ref={this.suggestModalRef} />

            </>

        )
    }
}
