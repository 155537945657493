import React, {Component} from 'react'

import {Layout, Dropdown, Menu, Avatar, Popover} from 'antd';
import { withRouter} from 'react-router-dom'
import {Aside} from '../../components'
import {loginOutRequest,getUserDetail} from '../../request'
import {envHttpUrl} from '../../http/globalMethod'

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DownOutlined,
    LogoutOutlined,
    DeploymentUnitOutlined,
    UserOutlined
} from '@ant-design/icons';
import * as Icon from "@ant-design/icons";
const { Header, Content } = Layout;
const  locationQuery = new URLSearchParams(window.location.search)
const iFrameParam = locationQuery.get('iframe')
class Frame extends Component {
    state = {
        collapsed: false,
        clientWidth:document.body.clientWidth
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                clientWidth:document.body.clientWidth
            })
        });
    }
    render() {
        return (
            <Layout hasSider={false}>
                {
                    iFrameParam!='iframe'?
                        <Header className="site-layout-background header-section" >
                            <div className="logo-wrap">
                                {
                                    this.state.clientWidth>=500?
                                        React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                            className: 'trigger',
                                            onClick: this.toggle,
                                        }):
                                        <Popover placement={'rightTop'} content={
                                            <Aside sys={this.props.sys} sysLink={this.props.sysLink} collapsed={this.state.collapsed}/>
                                        }>
                                            <Icon.MenuOutlined className={'trigger'}/>
                                        </Popover>
                                }
                                {/*{React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {*/}
                                {/*    className: 'trigger',*/}
                                {/*    onClick: this.toggle,*/}
                                {/*})}*/}
                                <div className="logo">{this.props.title}</div>

                            </div>
                        </Header>
                        :''
                }
                <Layout className="site-layout">
                    {
                        iFrameParam!='iframe'?
                            this.state.clientWidth>=500?
                                <Aside sys={this.props.sys} sysLink={this.props.sysLink} collapsed={this.state.collapsed}/>
                                :''
                            :''
                    }
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px',
                            minHeight: 280,
                            overflowY:'scroll'
                        }}>
                        {this.props.children}

                    </Content>
                </Layout>
            </Layout>
        )
    }
}
export default withRouter(Frame)