import axios from 'axios'
import { notification } from 'antd';
import QS from 'qs'
import {envHttpUrl} from '../http/globalMethod'
axios.defaults.withCredentials = true;

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// 请求超时时间
axios.defaults.timeout = 10000;
let baseUrl= window.location.protocol+envHttpUrl().baseUrl;
const setPageRouterUrl = (params)=>{
    let regRex = /^\/(\w)*(\/(\w)*)?/g
    let pageRouter = window.location.pathname.match(regRex)
    params.pageRouterUrl = pageRouter[0];
}
//响应拦截器
axios.interceptors.response.use(function (response) {
    if(response.status != 200){
        notification.error({
            message: '错误提示',
            description:'网络异常,请重试',
        });
    }else{
        switch (response.data.status){
            case 20112:
                // store.dispatch({
                //     type:actionType.LOGIN_FAILED
                // })
                let returnPage = encodeURIComponent(window.location.href)
                window.location.href = `${window.location.protocol}${envHttpUrl().webUrl}/login?auth=1&returnPage=${returnPage}`

                break;
            default:
                // notification.error({
                //     message: '错误提示',
                //     description:response.data.message||'网络异常,请重试',
                // });
                break;

        }
    }
    return response;

}, function (error) {
    return Promise.reject(error);
});

/**
 * 接口报错统一处理
 * **/
function catchErrorInfo(response) {
    /**
     * 接口错误处理
     * 20112未登录
     * 其他错误统一弹窗:错误码+错误信息(暂时)
     * **/
    if(response.status != 200){
        notification.error({
            message: '错误提示',
            description:'网络异常,请重试',
        });
    }else{
        switch (response.data.status){
            case 20112:
                // routerVue.push('/login')
                break;
            default:
                notification.error({
                    message: '错误提示',
                    description:response.data.message||'网络异常,请重试',
                });

        }
    }
}
/**
 *Get
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiGetRequest = (url, params={}) =>{
    axios.defaults.baseURL = baseUrl
    setPageRouterUrl(params)
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params})
            .then(res => {
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                    reject({message:res.data.message})
                }
            })
            .catch(err => {
                notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })

    });
}
/**
 *full链接的get
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiGetFullUrlRequest = (url, params={}) =>{
    axios.defaults.baseURL = ''

    setPageRouterUrl(params)
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params})
            .then(res => {
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                    reject({message:res.data.message})
                }
            })
            .catch(err => {
                notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })

    });
}/**
 *full链接的get
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const downloadUrlRequest = (url, params={}) =>{
    let fileLink = document.createElement('a')
    fileLink.setAttribute('download','')
    fileLink.href = `${baseUrl}${url}?pageRouterUrl=${window.location.pathname}`
    document.body.appendChild(fileLink)
    fileLink.click()
    document.body.removeChild(fileLink)
}
export const downloadImgRequest = (url, params={}) =>{
    return new Promise((resolve, reject) => {
        axios.get(url, {responseType:'blob'},)
            .then(res => {
                resolve()
            })
            .catch(err => {
                notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })

    });
}
/**
 *Post
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiPostRequest = (url, params={}) =>{
    axios.defaults.baseURL = baseUrl

    setPageRouterUrl(params)
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params, {
            skipNulls: true
        }))
            .then(res => {
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                    reject({message:res.data.message})
                }

            })
            .catch(err => {
                notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })
    });
}
export const apiPostJsonRequest = (url, params={}) =>{
    axios.defaults.baseURL = baseUrl

    setPageRouterUrl(params)
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify(params),{headers: {'Content-Type': 'application/json;charset=UTF-8'}})
            .then(res => {
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                    reject({message:res.data.message})
                }

            })
            .catch(err => {
                notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })
    });
}
